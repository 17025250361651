<template>
    <BY3 v-if="$store.state.LandTo.split('QWER')[0] === 'BY2' && CurrYY3" :CurrYY="CurrYY3" @FromBY3="$emit('FromBY')"></BY3>
    <div v-else>
        <br>
        <div class="XLR YC">
            <div class="W12"></div>
            <div class="TF">被约信息：{{BYs.length}}条</div>
            <el-button-group>
                <el-button type="primary" @click="ToCurr">当前</el-button>
                <el-button type="primary" @click="ToHistory">历史</el-button>
            </el-button-group>
            <div></div>
        </div>
        <div class="BottGray">&nbsp;</div>
        <div v-if="Show">
            <div v-for="(y,I1) in BYs" :key="I1">
                <div @click="SeleI=I1" class="XLR YC T4 TG MP4 BottGray">
                    {{I1+1}}.{{y.MeetTimeStr.substr(5,20)+'➱'+y.zyUserName+":"+y.ItemStr.split(':')[1]}}
                    <el-icon><ArrowRight/></el-icon>
                </div>
            </div>
            <PopTitle v-if="SeleI !== -1" :Title="'项目：'+Y.ItemStr.split(':')[1]" @FromPopUp="SeleI=-1">
                <BY3 :CurrYY="Y" @FromBY3="SeleI=-1"></BY3>
            </PopTitle>
        </div>
        <div v-else>
            <div v-for="(y,I1) in BYs" :key="I1">
                <div @click="SeleI=I1" class="XLR YC T4 TB MP4 BottGray">
                    {{I1+1}}.{{y.MeetTimeStr.substr(5,20)+'➱'+y.zyUserName+":"+y.ItemStr.split(':')[1]}}
                    <el-icon><ArrowRight/></el-icon>
                </div>
            </div>
            <PopTitle v-if="SeleI !== -1" :Title="'项目：'+Y.ItemStr.split(':')[1]" @FromPopUp="SeleI=-1">
                <OpenHistoryBY :CurrYY="Y"></OpenHistoryBY>
            </PopTitle>
        </div>
    </div>
</template>

<script>
    import {Compare, myMongoDBPost,UrlParse} from '@/components/SharedVues/Shared0.js'
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import BY3 from "./BY3";
    import MyToast from "@/components/SharedVues/MyToast";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import OpenHistoryBY from "./OpenHistoryBY";

    export default {
        name: "BY",
        components: {
            OpenHistoryBY,
            PopTitle,
            MyToast,
            BY3,
            ShowPVT
        },
        data() {
            return {
                Show: true,
                BYs: '',
                CurrYY3: null,
                SeleI: -1
            }
        },
        computed: {
            Y() {
                return this.BYs[this.SeleI]
            }
        },

        mounted() {
            let that = this, CurrYYId = UrlParse().CurrYYId;
            if (this.$store.state.LandTo.split('QWER')[0] === 'BY2') {//如果从后台读入的BYMsgs中，不包含该url中的CurrYYId，则提示“已失效!”
                myMongoDBPost("GetMyYYs", {fyUserName: this.$store.state.UserName, EndBool: false}, function (data) {//读取邀约信息数据
                    that.BYs = data.bys;
                    for (let i = 0, len = data.bys.length; i < len; i++) {
                        if (data.bys[i]._id === CurrYYId) {
                            that.CurrYY3 = data.bys[i];
                            break;
                        }
                    }
                    if (that.CurrYY3 === null) {
                        alert('该链接已失效！');
                        that.$store.commit('ChangeLandTo', undefined);
                        that.$emit('FromBY')
                    }
                })
            } else this.ToCurr();
        },
        methods: {
            SetSeleI(Val) {
                this.SeleI = Val;
            },
            ToCurr() {
                let that = this;
                myMongoDBPost("GetMyYYs", {fyUserName: that.$store.state.UserName, EndBool: false}, function (data) {//读取邀约信息数据
                    data.bys.sort(Compare('Stamp'));
                    that.BYs = data.bys.reverse();
                    that.Show = true
                });

            },
            ToHistory() {
                let that = this;
                myMongoDBPost("GetMyYYs", {fyUserName: that.$store.state.UserName, EndBool: true}, function (data) {//读取邀约信息数据
                    data.bys.sort(Compare('Stamp'));
                    that.BYs = data.bys.reverse();//data.bys.sort(Compare('Stamp'));
                    that.Show = false
                });

            },
        }
    }
</script>

<style scoped>
</style>
