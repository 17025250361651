<template>
    <ZY3 v-if="$store.state.LandTo === 'ZY2' && CurrYY3" :CurrYY="CurrYY3" @FromZY3="$emit('FromZY')"></ZY3>
    <div v-else>
        <br>
        <div class="XLR YC">
            <div class="W12"></div>
            <div class="TF">主约信息：{{ZYs.length}}条</div>
            <el-button-group>
                <el-button type="primary" @click="ToCurr">当前</el-button>
                <el-button type="primary" @click="ToHistory">历史</el-button>
            </el-button-group>
            <div></div>
        </div>
        <div class="BottGray">&nbsp;</div>
        <div v-if="Show && ZYs.length>0">
            <div v-for="(x,I1) in ZYs" :key="I1">
                <div class="XLR YC TG T4 MP4 BottGray" @click="SeleI=I1">
                    {{I1+1}}.{{x.MeetTimeStr.substr(5,20)+'➱'+x.FirstBy+'等➱'+x.ItemStr.split(':')[1]}}
                    <el-icon><ArrowRight/></el-icon>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-for="(x,I1) in ZYs" :key="I1">
                <div class="XLR YC T4 TB MP4 BottGray" @click="SeleI=I1">
                    {{I1+1}}.{{x.MeetTimeStr.substr(5,20)+'➱'+x.ItemStr.split(':')[2]+'：'+x.ItemStr.split(':')[1]}}
                    <el-icon><ArrowRight/></el-icon>
                </div>
            </div>
        </div>
    </div>
    <PopTitle v-if="SeleI !==-1" :Title="ZYs[SeleI].ItemStr.split(':')[2]+'：'+ZYs[SeleI].ItemStr.split(':')[1]" @FromPopUp="SeleI=-1">
        <ZY3 v-if="Show" :CurrYY="ZYs[SeleI]" @FromZY3="SeleI=-1"></ZY3>
        <OpenHistoryZY v-else :CurrYY="ZYs[SeleI]"></OpenHistoryZY>
    </PopTitle>
</template>

<script>
    import {Compare, myMongoDBPost, UrlParse} from '@/components/SharedVues/Shared0.js'
    import ZY3 from "./ZY3";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import ShowPVT from "@/components/MyPVTs/ShowPVT";
    import OpenHistoryZY from "./OpenHistoryZY";

    export default {
        name: "ZY",
        components: {OpenHistoryZY, ShowPVT, PopTitle, ZY3},
        data() {
            return {
                Show: true,
                ZYs: [],
                CurrYY3: null,
                SeleI: -1
            }
        },
        mounted() {
            let that = this, CurrYYId = UrlParse().CurrYYId;
            if (this.$store.state.LandTo === 'ZY2') {
                myMongoDBPost("GetMyYYs", {fyUserName: this.$store.state.UserName, EndBool: false}, function (data) {//读取邀约信息数据
                    that.ZYs = data.zys;
                    for (let i = 0, len = data.zys.length; i < len; i++) {
                        if (data.zys[i]._id === CurrYYId) {
                            that.CurrYY3 = data.zys[i];
                            break;
                        }
                    }
                    if (that.CurrYY3 === null) {
                        alert('该链接已失效！');
                        that.$store.commit('ChangeLandTo', undefined);
                        that.$emit('FromZY')
                    }
                })
            } else this.ToCurr();
        },
        methods: {
            ToCurr() {
                let that = this;
                this.SeleI = -1;
                that.ZYs = [];
                myMongoDBPost("GetMyYYs", {fyUserName: that.$store.state.UserName, EndBool: false}, function (data) {//读取邀约信息数据
                    console.log("data.zys=", data.zys)
                    data.zys.sort(Compare('Stamp')).reverse();
                    for (let i1 = 0, len1 = data.zys.length; i1 < len1; i1++) {
                        if (data.zys[i1].by.length > 0) {
                            data.zys[i1].FirstBy = data.zys[i1].by[0]._id;
                            for (let i2 = 0, len2 = data.zys[i1].by.length; i2 < len2; i2++)
                                data.zys[i1].by[i2].ChangedBool = 0;//为主约的每一个被约增加一个更新标志
                            that.ZYs.push(data.zys[i1])
                        }
                    }
                    that.Show = true

                });
            },
            ToHistory() {
                let that = this;
                this.SeleI = -1;
                myMongoDBPost("GetMyYYs", {fyUserName: that.$store.state.UserName, EndBool: true}, function (data) {//读取邀约信息数据　
                    data.zys.sort(Compare('Stamp'));
                    that.ZYs = data.zys.reverse();
                    that.Show = false
                });

            }
        }
    }
</script>

<style scoped>
</style>
