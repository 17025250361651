<template>
    <div :class="Show>0 ? 'MaskCss':''">
        <div v-if="$store.state.UserName === $store.state.HTM0" class="MTB2">
            <MyDivider ShowStr="后台管理"></MyDivider>
            <div class="XLR MP4 TB" @click="Show=1">
                用户实名认证审核
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=2">
                权限资格审核
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=4">
                用户申请注销审核
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=5">
                数据库原始数据
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=6">
                用户信息数据
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=7">
                项目名称与分类数据
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
        </div>
        <MyDivider ShowStr="基本资料"></MyDivider>
        <div>
            <div v-if="$store.state.Certified==='成功'" class="XLR MP4 TF">实名认证已完成</div>
            <div v-else class="XLR MP4 TE" @click="Show=11">
                实名认证尚未完成
                <el-icon><ArrowDown/></el-icon>
            </div>
            <el-divider/>
            
            <div class="XLR MP4 TB" @click="Show=12">
                当前注册信息(绝对私密)
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=13">
                附加用户信息(可以公开)
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TG" @click="Show=14">默认打开项目更改
                <el-icon> <ArrowRight/> </el-icon>
            </div>
            <el-divider/>
        </div>
        <MyDivider ShowStr="权限资格申请"></MyDivider>
        <div>
            <div v-if="$store.state.UserBools.indexOf('活动管理员')>=0" class="XLR MP4 TB">
                活动管理员资格
                <el-icon><CircleCheck/></el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =15">
                活动管理员资格
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div v-if="$store.state.UserBools.indexOf('场地管理员')>=0" class="XLR MP4 TB">
                场地管理员资格
                <el-icon><CircleCheck/></el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =16">
                场地管理员资格
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div v-if="$store.state.UserBools.indexOf('泛约影像师')>=0" class="XLR MP4 TB">
                泛约影像师资格
                <el-icon><CircleCheck/></el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =17">
                泛约影像师
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div v-if="$store.state.UserBools.indexOf('泛约裁判员资格')>=0" class="XLR MP4 TB">
                泛约裁判员资格
                <el-icon><CircleCheck/></el-icon>
            </div>
            <div v-else class="XLR MP4 TB" @click="Show =18">
                泛约裁判员资格
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
        </div>
        <MyDivider ShowStr="我的泛约应用动态"></MyDivider>
        <div>
            <div class="XLR MP4 TB" @click="Show21 = !Show21">
                泛约指数与排名
                <div v-if="Show21">
                    <el-icon><ArrowDown/></el-icon>
                </div>
                <div v-else>
                    <el-icon><ArrowUp/></el-icon>
                </div>
            </div>
            <MyIndexs v-if="Show21"></MyIndexs>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=22">邀约设置信息
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=23">喜好项目场地
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            <div class="XLR MP4 TB" @click="Show=24">
                关联用户/泛约人脉圈
                <el-icon><ArrowRight/></el-icon>
            </div>
            <el-divider/>
            
            <QuitFY v-if="$store.state.UserName!=='游客'" @FromQuitFY="$emit('FromSetupMain')"></QuitFY>
        </div>
        <br>
    </div>
    <PopTitle v-if="Show===1" Title="用户实名认证审核" @FromPopUp="Show=-1">
        <UserCertify></UserCertify>
    </PopTitle>
    <PopTitle v-if="Show===2" Title="权限资格审核" @FromPopUp="Show=-1">
        <QXCheck></QXCheck>
    </PopTitle>
    <PopTitle v-if="Show===4" Title="用户申请注销审核" @FromPopUp="Show=-1">
        <UserWithdrawCheck></UserWithdrawCheck>
    </PopTitle>
    <PopTitle v-if="Show===5" Title="数据库原始数据" @FromPopUp="Show=-1">
        <HTDatabases></HTDatabases>
    </PopTitle>
    <PopTitle v-if="Show===6" Title="用户信息数据" @FromPopUp="Show=-1">
        <HTUserRegMsg></HTUserRegMsg>
    </PopTitle>
    <PopTitle v-if="Show===7" Title="项目名称与分类数据" @FromPopUp="Show=-1">
        <HTItems></HTItems>
    </PopTitle>
    
    <PopTitle Title="实名认证" v-if="Show===11" @FromPopUp="Show=-1">
        <AskRealName @FromAskRealName="Show=-1"></AskRealName>
    </PopTitle>
    <PopTitle v-if="Show===12" Title="当前注册信息" @FromPopUp="Show=-1">
        <DLandReg></DLandReg>
    </PopTitle>
    <PopTitle v-if="Show===13" Title="附加用户信息编辑" @FromPopUp="Show=-1">
        <FJXX></FJXX>
    </PopTitle>
    <PopBott v-if="Show===14">
        <SeleOneItem Title="请先选择缺省打开项目" @FromSeleOneItem="UpdateDefaultItem"></SeleOneItem>
    </PopBott>
    
    
    <PopTitle v-if="Show===15" Title="活动管理员资格申请" @FromPopUp="Show=-1">
        <QXAsk QX="活动管理员" @FromQXAsk="Show=-1"></QXAsk>
    </PopTitle>
    
    <PopTitle v-if="Show===16" Title="场地管理员资格申请" @FromPopUp="Show=-1">
        <QXAsk QX="场地管理员" @FromQXAsk="Show=-1"></QXAsk>
    </PopTitle>
    <PopTitle v-if="Show===17" Title="泛约影像师资格申请" @FromPopUp="Show=-1">
        <QXAsk QX="泛约影像师" @FromQXAsk="Show=-1"></QXAsk>
    </PopTitle>
    <PopTitle v-if="Show===18" Title="泛约裁判员资格申请" @FromPopUp="Show=-1">
        <QXAsk QX="泛约裁判员" @FromQXAsk="Show=-1"></QXAsk>
    </PopTitle>
    
    
    <PopTitle v-if="Show===22" Title="我的邀约设置信息" @FromPopUp="Show=-1">
        <p v-if="$store.state.UserName==='游客'" class="TF">本功能游客用户操作无效！</p>
        <MyBySet v-else></MyBySet>
    </PopTitle>
    <PopTitle v-if="Show===23" Title="我的喜好项目场地" @FromPopUp="Show=0">
        <MySites></MySites>
    </PopTitle>
    <PopTitle v-if="Show===24" Title="我的泛约人脉圈" @FromPopUp="Show=-1">
        <RUsers></RUsers>
    </PopTitle>
</template>

<script>
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";
    import {myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import QXAsk from "./QXAsk";
    import UserCertify from "../SetupManage/UserCertify";
    import QXCheck from "../SetupManage/QXCheck";
    import UserWithdrawCheck from "../SetupManage/UserWithdrawCheck";
    import HTDatabases from "../SetupManage/HTDatabases";
    import HTUserRegMsg from "../SetupManage/HTUserRegMsg";
    import HTItems from "../SetupManage/HTItems";
    import DLandReg from "./DLandReg";
    import FJXX from "./FJXX";
    import SiteCreate from "../M4_Sites/SiteCreate";
    import SitesGetTJ from "../M4_Sites/SitesGetTJ";
    import ZXAccounts from "../GXCardsandZXGL/ZXAccounts";
    import SitesList from "../M4_Sites/SitesList";
    import MyIndexs from "./MyIndexs";
    import QXZG from "./QXZG";
    import QuitFY from "./QuitFY";
    import FYDT from "./FYDT";
    import PopUpDown from "../MyPopWin/PopUpDown";
    import SiteManage from "../M4_Sites/SiteManage";
    import PopBott from "../MyPopWin/PopBott";
    import AskRealName from "../M0_RegLogin/AskRealName";
    import SeleOneItem from "../FrontPage/SeleOneItem";
    import RUsers from "./RUsers";
    import MySites from "../M4_Sites/MySites";
    import MyBySet from "./MyBySet";
    import MyDivider from "../SharedVues/MyDivider";

    export default {
        name: "SetupMain",
        components: {
            MyDivider,
            MyBySet,
            MySites,
            RUsers,
            SeleOneItem,
            AskRealName,
            PopBott,
            SiteManage,
            PopUpDown,
            QuitFY,
            QXZG,
            MyIndexs,
            SitesList,
            ZXAccounts,
            SitesGetTJ,
            SiteCreate,
            FJXX,
            DLandReg,
            HTItems,
            HTUserRegMsg,
            HTDatabases,
            UserWithdrawCheck,
            QXCheck,
            UserCertify,
            QXAsk, FYDT,
            ShowUpdatePV,
            PopTitle
        },
        data() {
            return {
                Show: -1,
                Show21: false,
            }
        },
        methods: {
            UpdateDefaultItem(Val) {
                let that = this;
                that.Show = -1;
                myMongoDBPost("AddbySetsItem", {UserName: this.$store.state.UserName, ItemStr: Val}, function (data) {
                });
                myMongoDBPost("UpdateDoc/fyuser0", {UPTJ: {UserName: this.$store.state.UserName}, UPObj: {DefaultItem: Val}}, function () {
                    that.$store.commit('ChangeDefaultItem', Val);
                });
            },
        }
    }
</script>

<style scoped>

</style>
