<template>
    <div class="MP4 XL YC BottGray">
        用户名:
        <div class="BoxGray1 MP4 W50 H" @click="InputI=3">
            <span v-if="UserDoc.UserName===''" class="TF">不一定是微信昵称</span>
            <span v-else class="TC">{{UserDoc.UserName}}</span>
        </div>
        <div v-if="Existed1" class="TE T6">?</div>
        <div v-else>✓</div>
    </div>
    <div v-if="UserNames.length>0" class="PA3" style="background-color: #a8a8a8">
        <div>已被使用的用户名：</div>
        <div v-for="(UN,index) in UserNames" :key="index" class="DispIB PA3">{{UN}}</div>
    </div>
    <div class="MP4 XL YC BottGray">
        登录密码:
        <div class="BoxGray1 MP4 W50 H TG" @click="InputI=4">
            <span v-if="UserDoc.Password===''" class="TF">6-18位数字</span>
            <span v-else v-for="(C,index) in UserDoc.Password" :key="index">●</span>
        </div>
        <div v-if="TJ2" class="TE T6">?</div>
        <div v-else>✓</div>
    </div>
    <div class="MP4 XLR YC">
        性别:
        <div class="XL YC">
            <input id="Sex1" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="男">
            <label for="Sex1"> 男</label>
        </div>
        <div class="XL YC">
            <input id="Sex2" type="radio" class="AlignT" name="Sex" v-model="UserDoc.Sex" value="女">
            <label for="Sex2"> 女</label></div>
        <div></div>
        <div></div>
    </div>
    
    <div class="MP4 XL YC">
        出生日期:&nbsp;
        <input class="DispIB W20" type="number" v-model.number="UserDoc.BirthYear" @blur="Certify()"/>年
        <input class="DispIB W12" type="number" v-model.number="UserDoc.BirthMonth" @blur="Certify()"/>月
        <input class="DispIB W12" type="number" v-model.number="UserDoc.BirthDay" @blur="Certify()"/>日
    </div>
    
    <div class="MP4 XL YC">常驻地：
        <FyAddr @FromFyAddr="SetFyAddr"></FyAddr>
    </div>
    <br><br>
    <div class="PA3">
        <div class="MP4 XLR YC">
            <div class="XL YC">
                我已阅读并同意 <a class="TB" @click="ShowProtocol=true,Protocoled=true">《用户协议》</a>
            </div>
            <input type="checkbox" class="aui-switch" v-model="Protocoled"/>
        </div>
        <div v-if="AllOk && Protocoled" class="BD_Radius1 BGBlue T5 TW XYC H3 MT2" @click.once="FYReg0()">注册
        </div>
        <div v-else class="BD_Radius1 T5 TW XYC H3 MT2" style="background-color: red">请完整回答以上注册信息！</div>
    </div>
    <br>
    <PopTitle v-if="ShowProtocol" Title="泛约用户协议书" @FromPopUp="ShowProtocol=false">
        <div class="MLR2">
            <div v-for="(A,index) in Articles" :key="index">
                <div v-if="A.MFormat==='文章标题'" class="AlignC">
                    <h4>{{A.Str1}}</h4>
                </div>
                <div v-if="A.MFormat==='二级标题'" class="MT4B2">
                    <h5>{{A.Str1}}</h5>
                </div>
                <div v-if="A.MFormat==='缩进'">
                    <p>{{A.Str1}}</p>
                </div>
            </div>
        </div>
    </PopTitle>
    <MyInput v-if="InputI===3" Title="泛约昵称" Type="text" :Begin="UserDoc.UserName"
             @FromMyI="MyInputVal"></MyInput>
    <MyInput v-if="InputI===4" Title="登录密码" Type="P2" :Begin="UserDoc.Password" @FromMyI="MyInputVal"></MyInput>
</template>

<script>
    import FYProtocol from "@/components/M0_RegLogin/FYProtocol"
    import {myDataFileGet, myMongoDBPost} from '@/components/SharedVues/Shared0.js'
    import FyAddr from "@/components/SharedVues/FyAddr"
    import MyInput from "@/components/SharedVues/MyInput";
    import ShowUpdatePV from "../MyPVTs/ShowUpdatePV";
    import MySelectStr from "../SharedVues/MySelectStr";
    import PopTitle from "../MyPopWin/PopTitle";
    import PopTop from "../MyPopWin/PopTop";

    export default {
        name: "FYReg",
        components: {
            PopTop,
            PopTitle,
            MySelectStr,
            ShowUpdatePV, FYProtocol,
            MyInput, FyAddr
        },
        data() {
            return {
                Show: -1,
                BirthStr: '1960-11-12',
                Password2: '',
                ShowProtocol: false,
                Protocoled: false,
                Articles: null,

                ProtocolOk: false,
                CurrStep: 0,
                AllOk: false,
                InputI: -1,
                dlData: {
                    fyUserName: "",
                    fyPassword: "",
                    AutoDl: false
                },
                ThisYear: (new Date).getFullYear(),
                UserDoc: {
                    UserName: "",
                    Password: "",
                    BirthYear: null,//出生年份
                    BirthMonth: null,
                    BirthDay: null,
                    Sex: "？",

                    IDNumber: "",//身份证号
                    FixedAddr: {N: '中国', P: '', C: '', DC: ''},
                    RealName: "",
                    Mobile: '',
                    UserLogo: '',//泛约用户Logo,建议个人用户使用自己的头像
                    WXId: this.$store.state.WXId,
                    UserBools: [],
                    RegTimeStamp: (new Date()).getTime(),//注册时间{type: Date, default: Date.now}
                    Pic: '',
                    Video: '',
                    Certified: '',
                    XYZ: {},
                },//vue版本暂时只允许个人用户注册PG:0
                UserNames: [],
                Existed1: true,

                TJ0: true,
                TJ1: true,
                TJ2: true,

            }
        },
        mounted() {
            let that = this;
            //此处应检查当前微信号下是否已注册过，若已注册过，应给出提示，并且取消继续注册

            if (this.$store.state.WXId !== '') myMongoDBPost("GetDocs/fyuser0", {WXId: this.$store.state.WXId}, function (Docs) {
                if (Docs.length > 0) {
                    alert('同一微信号下只能注册一个用户，您已使用[' + Docs[0].UserName + ']完成了注册，请直接登录即可。');
                    that.$emit("FromReg", -1);
                }
            });

            myDataFileGet("DataDictionary/?FindWhat=Protocol1", function (Docs) {
                that.Articles = Docs;
            });
        },

        methods: {
            HandleFocus(event) {
                event.target.blur();
            },
            SetFyAddr(Val) {
                this.UserDoc.FixedAddr = {N: '中国', P: Val.P, C: Val.C, DC: Val.DC};
                console.log(this.UserDoc.FixedAddr)
            },
            MyInputVal(Val) {
                let that = this;
                if (Val !== '') {
                    if (this.InputI === 3) {
                        if (Val === '游客') alert('错误提示，本平台禁用“游客”二字作为泛约昵称'); else {
                            this.UserDoc.UserName = Val;
                            if (Val === '决不放弃') this.UserDoc.FixedAddr = {N: '中国', P: '天津', C: '天津市', DC: '东丽区'};
                            myMongoDBPost("GetDocs/fyuser0", {UserName: {$regex: Val}}, function (Docs) {
                                that.UserNames = [];
                                that.Existed1 = false;
                                let len = Math.min(Docs.length, 50);
                                for (let i = 0; i < len; i++) {
                                    that.UserNames.push(Docs[i].UserName);
                                    if (Docs[i].UserName === Val) {
                                        that.Existed1 = true;
                                        that.UserDoc.UserName = '';
                                        that.Certify();
                                        alert("该昵称已被使用!")
                                    }
                                }
                            })
                        }
                    }
                    if (this.InputI === 4 && Val.length > 5) this.UserDoc.Password = Val;
                }
                this.InputI = -1;
                this.Certify();
            },
            ChangeSex(Val) {
                this.UserDoc.Sex = Val;
                this.Certify();
            },
            Certify() {
                this.AllOk = true;
                this.TJ0 = (this.UserDoc.Sex === '？' || this.UserDoc.BirthYear === null || this.UserDoc.BirthMonth === null || this.UserDoc.BirthDay === null);
                this.TJ1 = (this.Existed1 || this.UserDoc.UserName === '不一定是微信昵称' || this.UserDoc.UserName.replace(/[ ]/g, '') === '游客');
                this.TJ2 = this.UserDoc.Password === '';
                if (this.TJ0 || this.TJ1 || this.TJ2) this.AllOk = false;
            },
            FYReg0() {
                let that = this;
                if (that.UserDoc.UserName !== '游客' && that.UserDoc.UserName !== '') {

                    myMongoDBPost("PutDoc/fyuser0", that.UserDoc, function (data) {
                        that.dlData = {
                            fyUserName: that.UserDoc.UserName,
                            fyPassword: that.UserDoc.Password,
                            AutoDl: false
                        };
                        //为新注册用户自动设置一些初始的邀约项目
                        let InitItems = ["A000:乒乓球", "A002:台球", "B001:洗浴", "B002:KTV", "B012:美食"];
                        let i = 0;

                        function Recursion() {
                            if (i < InitItems.length) {
                                myMongoDBPost("AddbySetsItem", {UserName: that.UserDoc.UserName, ItemStr: InitItems[i]}, function () {
                                    i++;
                                    Recursion();
                                });
                            } else that.$emit("FromReg", -1);
                        }

                        Recursion();
                    });

                } else alert('注册失败!，用游客作为用户名不能注册');
            },

        }

    }
</script>

<style scoped>
    
    
    .W55 {
        width: 55vw
        }
    
    .H {
        height: 1.5rem;
        line-height: 150%;
        }
</style>
