<template>
    <div class="ANRed PA1 MLR2 TW T2 LH200">
        <div class="XR T5" style="color:#d60000;font-weight: bold;">邀 约 信 息 卡&nbsp;</div>
        已选被约：
        <div class="DispIB MLR2 TY BottO " v-for="(x,index) in CurrYYDoc.by" :key="index">{{x._id}}</div>
        <div v-if="CurrYYDoc.by.length===0" class="TY T2 AlignC">尚未选择邀约对象</div>
        <div v-if="CurrYYDoc.MeetTimeStr!=='yy-MM-ddd hh:mm'" class="TW T2">碰头时间：{{CurrYYDoc.MeetTimeStr}}</div>
        <div v-if="CurrYYDoc.MeetAddrStr!==''" class="TW T2">碰头地点：{{CurrYYDoc.MeetAddrStr}}</div>
        <div v-if="CurrYYDoc.jdfy!==''" class="TW T2">简短附言：{{CurrYYDoc.jdfy}}</div>
    </div>
    <div v-if="YYStepSele===-1" class="AlignC MP4 T9">
        邀约信息已发出！
    </div>
    <div v-else>
        <div class="XR PA2">
            <el-button-group>
                <el-button type="primary" :icon="ArrowLeft" @click="SetYYStepSele(-1)"><span :class="YYStepSele>0 ? 'TW':'TF'">上一步</span></el-button>
                <el-button type="primary" @click="SetYYStepSele(1)">
                <span :class="YYStepSele<3 ? 'TW':'TF'">下一步
                <el-icon class="el-icon--right"><ArrowRight/></el-icon></span>
                </el-button>
            </el-button-group>
        </div>
        <div class="BGY">
            <el-divider>步骤{{(YYStepSele+1)+': '+['选择被约', '碰头时间地点', '其它约定', '确认'][YYStepSele]}}</el-divider>
            <div v-show="YYStepSele===0" class="MT2">
                <div class="BottGray XLR YC PA2">
                    <div class="XL YC">
                        范围：
                        <FYArea></FYArea>
                    </div>
                    <div v-if="CurrTM===0" class="XL YC">
                        <div>积分场地：</div>
                        <div class="DispIB TB PA2 BoxGray1" @click="Show3=true,CurrClub=''">{{CurrClub}}</div>
                    </div>
                    <div v-else></div>
                </div>
                <MySele1 v-if="Show3" :Opts="Clubs" :ColN="3" @FromMySele1="SetCurrClub"></MySele1>
                <TM0bySele v-if="CurrTM===0 && CurrClub!==''" :CurrClub="CurrClub" :ZyJF="CurrYYDoc.JF" @eventFromTM0="SetBy"></TM0bySele>
                <TM1bySele v-if="CurrTM===1" @eventFromTM="SetBy"></TM1bySele>
                <TM2bySele v-if="CurrTM===2" @eventFromTM="SetBy"></TM2bySele>
                <TM3bySele v-if="CurrTM===3" @eventFromTM="SetBy"></TM3bySele>
                <div v-if="NoBY" class="TE AlignC MP4">找不到可选被约！请扩大选择范围或退出!</div>
                <div v-if="FromOpenUser !== undefined" class="MP4">{{FromOpenUser}}</div>
            </div>
            <div v-if="YYStepSele===1">
                <div class="PA3 TC">
                    碰头时间：<br>
                    <input class="W16" type="number" @focus="Year=null" min="1950" max="2020" v-model.number="Year" @blur="ToMeetTimeStr()"/>年
                    <input class="W6" type="number" @focus="Month=null" min="1" max="12" v-model.number="Month" @blur="ToMeetTimeStr()"/>月
                    <input class="W6" type="number" @focus="Day=null" min="1" max="31" v-model.number="Day" @blur="ToMeetTimeStr()"/>日
                    <input class="W6" type="number" @focus="Hour=null" min="6" max="22" v-model.number="Hour" @blur="ToMeetTimeStr()"/>时
                    <input class="W6" type="number" @focus="Minute=null" min="0" max="50" v-model.number="Minute" @blur="ToMeetTimeStr()"/>分
                </div>
                <div v-if="Show4">
                    <div class="PA3 TC XL YC">
                        碰头地点：
                        <FyAddr @FromFyAddr="SetFyAddr"></FyAddr>
                    </div>
                    <div class="XLR YC PA3 TC T3">
                        <div></div>
                        具体地点:
                        <div class="BoxGray1 W60 H PA3 TF" @click="SetInputI2">
                            <span v-if="AddrDA===''">(请精确到50米范围内!)</span>
                            <span v-else>{{AddrDA}}{{SiteName}}</span>
                        </div>
                    </div>
                    <MyDivider ShowStr="推荐场地(自动生成碰头地点)"></MyDivider>
                    <SitesShow :CurrYYDoc="CurrYYDoc" :ItemStr="ItemStr" @eventFromSitesShow="ResetAddr"></SitesShow>
                </div>
                <MyInput v-if="InputI===2" Title="具体地点" Type="text" :Begin="AddrDA" @FromMyI="MyInputVal"></MyInput>
            </div>
            <div v-if="YYStepSele===2">
                <div class="XL YC PA3 TF">
                    简短附言:<br>
                    <textarea v-model="CurrYYDoc.jdfy" class="Mtextarea2 Box5 MR4"></textarea>
                </div>
            </div>
            <div v-if="YYStepSele===3">
                <div class="MP4 T3">请务必认真核对以上邀约信息，然后再发送邀约。</div>
                <div v-if="YYSiteChecked" class="XLR">
                    <div></div>
                    <div class="T5 TW XYC H4 W50 BGDeepGreen" @click="SendYY1()">保存为经常性邀约</div>
                    <div class="T5 TW XYC H4 W50  BGBlue" @click="SendYY()" style="margin-left: 1px">发&nbsp;送&nbsp;邀&nbsp;约</div>
                    <div></div>
                </div>
                <div v-else class="TE MP4">碰头地点输入有误!</div>
                <div v-if="zyPic!=='' && $store.state.UserName === $store.state.HTM0" class="MT2">
                    <WXLink :WTitle="'【'+CurrYYDoc.ItemName+'】项目新玩法：赢取尊享。'" WText="谁赢了尊享归谁。"
                            :WLandTo="'XMYY'+CurrYYDoc.ItemCode+'XMYY'+CurrYYDoc.zyUserName" :Pic="'UserUpload/1/S'+zyPic"></WXLink>
                    <!--'擂主：'+CurrYYDoc.zyUserName+'。赢取尊享：'+CurrYYDoc.AppearanceFee+'个。'-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        GetC_I,
        GetItemStr3,
        GetRelativeTime,
        KeepZX,
        myMongoDBPost,
        SendWXMsg
    } from '@/components/SharedVues/Shared0.js' ;
    import TM0bySele from "./TM0bySele";
    import TM1bySele from "./TM1bySele";
    import TM2bySele from "./TM2bySele";
    import TM3bySele from "./TM3bySele";
    import PopTitle from "@/components/MyPopWin/PopTitle";
    import MyToast from "@/components/SharedVues/MyToast";
    import MyInput from "@/components/SharedVues/MyInput";
    import WXLink from "../IndependentVues/WXLink";
    import MySele1 from "../SharedVues/MySele1";
    import PopStep2 from "../MyPopWin/PopStep2";
    import FYArea from "../SharedVues/FYArea";
    import MyDivider from "../SharedVues/MyDivider";
    import SitesShow from "../M4_Sites/SitesShow";
    import FyAddr from "../SharedVues/FyAddr";
    import PopTop from "../MyPopWin/PopTop";
    //调用来源包括：1.主页：列表选择式邀约；2.微信菜单：邀约--选择式；3.微信链接--个人时间柜台；4.首页--时间商店--时间交易步骤规则--第5条的时间橱窗；
    export default {
        name: "XMYY",
        components: {
            PopTop,
            MyDivider,
            FYArea,
            PopStep2,
            MySele1,
            WXLink,
            MyInput,
            MyToast,
            PopTitle,
            TM0bySele,
            TM3bySele,
            TM2bySele,
            TM1bySele,
            SitesShow,
            FyAddr
        },
        props: ['ItemStr', 'FromOpenUser', 'AppearanceFee'],
        data() {
            return {
                Show: -1,
                CurrTM: -1,
                CurrClub: '泛约',
                Clubs: ['泛约'],
                NoBY: false,
                SendedMsg: '',
                Show3: false,
                Show4: true,
                YYStepSele: 0,
                PayWays: ['无费用', 'AA制', '主约付费', '被约付费'],
                AddrDA: '',
                SiteName: '',
                CurrYYDoc: {by: []},
                InputI: -1,
                zyPic: '',
                PCDC: {P: "省份", C: "城市", DC: "区县"},
                Year: new Date().getFullYear(),
                Month: new Date().getMonth()+1,
                Day: new Date().getDay(),
                Hour: new Date().getHours(),
                Minute: new Date().getMinutes()
            }
        },
        mounted() {
            let that = this;
            that.CurrTM = GetC_I(that.ItemStr);
            this.CurrYYDoc = {
                ItemCode: this.ItemStr.split(":")[0],
                ItemName: this.ItemStr.split(":")[1],
                ItemStr: this.ItemStr.split(":")[0] + ":" + this.ItemStr.split(":")[1],
                zyUserGrade: this.$store.state.Certified,
                zyUserName: this.$store.state.UserName,
                AppearanceFee: 0,
                JF: 0,
                MeetTimeStr: "yy-MM-dd hh:mm",
                MeetAddrStr: "",
                Longi: 116.38,
                Lati: 39.9,
                TimeWinStr: '',//HS:1,2;或 Y:3; 或 M:2; 或 W:2,3 或 WE:2,3 或 H:1
                by: [],
                jdfy: "",
                YYSiteChecked: false
            };
            if (this.FromOpenUser !== undefined) {
                this.YYStepSele = 1;
                this.CurrYYDoc.by = [{
                    "_id": that.FromOpenUser,
                    "xy": "未响应",
                    "z_zg": "初值",
                    "z_bylyqk": "初值",
                    "b_zg": "初值",
                    "b_zylyqk": "初值",
                    "zg": "初值",
                    "z_ts": "初值",
                    "b_ts": "初值",
                    "Pic": '',//线下活动情况
                    "Video": '',
                    "Text": '',
                    "AppearanceFee": this.AppearanceFee === undefined ? 0 : this.AppearanceFee
                }]
            }
            myMongoDBPost("GetDocs/byset0", {UserName: that.$store.state.UserName, ItemStr: that.ItemStr}, function (Docs2) {
                if (Docs2.length > 0) {
                    if (Docs2[0].Pic && Docs2[0].Pic.length > 0) that.zyPic = Docs2[0].Pic; else that.zyPic = that.$store.state.UserLogo;
                    for (let i = 0; i < Docs2[0].MySites.length; i++) that.Clubs.push(Docs2[0].MySites[i].SiteShortName);
                    that.CurrClub = (Docs2[0].CurrClub === '' ? '泛约' : Docs2[0].CurrClub);
                    that.CurrYYDoc.AppearanceFee = Docs2[0].AppearanceFee;
                    myMongoDBPost("GetDocs/fymc-" + that.CurrYYDoc.ItemStr.split(":")[0], {_id: that.$store.state.UserName}, function (Docs) {
                        if (Docs.length > 0) that.CurrYYDoc.JF = Docs[0].JF;
                        that.CurrTM = GetC_I(that.ItemStr);
                    });
                }
            });
            document.getElementById('TitleId').innerText = this.$store.state.UserName + "/" + this.$store.state.ZX + ">" + this.$store.state.DefaultItem.split(":")[1];
        },
        methods: {
            HandleFocus(event) {
                event.target.blur();
            },
            SetYYStepSele(N) {
                if (N === 1 && this.YYStepSele < 3) this.YYStepSele++;
                if (N === -1 && this.YYStepSele > 0) this.YYStepSele--;
                //if (this.YYStepSele === 1) this.CurrYYDoc.MeetTimeStr = GetRelativeTime('h', 1, "yy-MM-ddd hh:mm");//当前时间推后1小时,
                if (this.YYStepSele === 3 && this.CurrYYDoc.by.length === 0) {
                    this.YYStepSele = 0;
                    alert("尚未选择邀约对象1");
                }
            },
            SetFyAddr(Val) {
                this.PCDC = {P: Val.P, C: Val.C, DC: Val.DC};
            },
            ToMeetTimeStr() {
                this.CurrYYDoc.MeetTimeStr = this.Year + "-" + this.Month + "-" + this.Day + " " + this.Hour + ":" + this.Minute;
            },
            SetCurrClub(Val) {
                let that = this;
                this.CurrClub = (Val === '' ? '泛约' : Val);
                myMongoDBPost("UpdateDoc/bySet0", {
                    UPTJ: {UserName: this.$store.state.UserName, ItemStr: this.CurrYYDoc.ItemStr},
                    UPObj: {CurrClub: Val}
                }, function () {
                    that.Show3 = false;
                });
            },
            SetInputI2() {
                if (this.PCDC.P === '省份') alert("省份必须选择！");
                else if (this.PCDC.C === '城市') alert("城市必须选择！");
                else if (this.PCDC.DC === '区县') alert("区县必须选择！");
                else this.InputI = 2;
            },
            MyInputVal(Val) {
                this.AddrDA = Val;
                this.AddrChanged();
                this.InputI = -1;
            },
            AddrChanged() {
                this.CurrYYDoc.MeetAddrStr = this.PCDC.P + this.PCDC.C + this.PCDC.DC + this.AddrDA + this.SiteName;
                let X = this.PCDC.P !== '省份', Y = this.PCDC.C !== '城市', Z = this.PCDC.DC !== '区县';
                if ((X && Y && Z && this.AddrDA !== '')) this.YYSiteChecked = true;
            },
            SetBy(Val) {
                //console.log("this.FromOpenUser=" + this.FromOpenUser + "Val=" + Val)
                //console.log(Val)
                let that = this;
                if (this.FromOpenUser !== undefined) {
                    myMongoDBPost("GetDocs/byset0", {
                        UserName: that.FromOpenUser,
                        ItemStr: that.CurrYYDoc.ItemStr
                    }, function (Docs2) {
                        that.CurrYYDoc.by = [{
                            "_id": that.FromOpenUser,
                            "xy": "未响应",
                            "z_zg": "初值",
                            "z_bylyqk": "初值",
                            "b_zg": "初值",
                            "b_zylyqk": "初值",
                            "zg": "初值",
                            "z_ts": "初值",
                            "b_ts": "初值",
                            "Pic": '',//线下活动情况
                            "Video": '',
                            "Text": '',
                            "AppearanceFee": Docs2.length > 0 ? Docs2[0].AppearanceFee : 0
                        }];
                        that.NoBY = false;
                    })
                } else {
                    if (Val === 'NoBY') this.NoBY = true; else {
                        this.CurrYYDoc.by = Val;
                        this.NoBY = false;
                    }
                }

            },//看看从不同的子组件中反馈的事件是否通用?

            SendYY() {
                if (this.YYStepSele === 3) {
                    this.YYStepSele = -1;//为防止快速点击两次发送时的重复发送,设置本变量;
                    if (this.$store.state.UserName === '游客') return alert('为了最大限度的堵塞滥约,游客不能作为主约发送邀约!');
                    let C_S = this.CurrYYDoc.ItemCode.charAt(0);
                    let that = this, AppearanceFees = 0, byN = this.CurrYYDoc.by.length;
                    for (let i = 0, len = byN; i < len; i++) AppearanceFees += that.CurrYYDoc.by[i].AppearanceFee;
                    let Describe = C_S + ">" + this.CurrYYDoc.ItemCode + ">" + this.CurrYYDoc.ItemName + ",邀请被约" + byN + "个";
                    let ThisMoney = AppearanceFees;

                    //带币邀约规则：被约可以任意设置AppearanceFee，无须冻结。如果主约赢了则没有尊享转移；如果主约输了，则须转移尊享。
                    if (ThisMoney > 0) {
                        if (that.$store.state.ZX < ThisMoney)
                            alert("邀约无效：您的尊享存量不足! 本次需冻结：" + ThisMoney + "个尊享");
                        else
                            KeepZX(that, that.$store.state.UserName, '支', ThisMoney, Describe, function () {
                                that.SendYY3();
                                alert("当前邀约已发送... ，需冻结" + ThisMoney + "个尊享");
                            });
                    } else {
                        that.SendYY3();
                        alert('当前邀约已发送...，无需耗费尊享');
                    }
                }
            },
            SendYY1() {
                //this.$emit("eventFromXMYY", false);
                let that = this;
                if (this.$store.state.UserName === '游客') return alert('为了最大限度的堵塞滥约,游客不能作为主约发送邀约!'); else {
                    myMongoDBPost("SaveAsYYTemplet", this.CurrYYDoc, function () {
                        alert("当前邀约信息已保存为经常性邀约!");
                        that.$emit("eventFromXMYY", false);
                    })
                }
            },//存为模板
            SendYY3() {
                let that = this;
                //this.Timer = setTimeout(function () {          that.$emit("eventFromXMYY", false);        }, 1000)//确保动画完成
                myMongoDBPost("GetDocs/YYMBs", {
                    zyUserName: that.$store.state.UserName,
                    ItemStr: that.CurrYYDoc.ItemStr
                }, function (Docs) {
                    if (Docs.length === 0) myMongoDBPost("SaveAsYYTemplet", that.CurrYYDoc, function () {
                    })
                });
                myMongoDBPost("PutDoc/yy-" + that.CurrYYDoc.ItemCode, that.CurrYYDoc, function (aDoc) {//邀约发送成功后进行关联用户处理
                    let i, len;//向每一个被选中的被约发送消息
                    for (i = 0, len = that.CurrYYDoc.by.length; i < len; i++)
                        (function (i) {
                            let MyUrlPara = 'LandTo=BY2QWERItemCode=' + that.CurrYYDoc.ItemCode + 'QWERItemName=' + that.CurrYYDoc.ItemName + 'QWERCurrYYId=' + aDoc._id + '___';
                            let MyUrlShow = '立即查看/回复';
                            let Msg = GetItemStr3(that.CurrYYDoc.ItemStr) + "☞『" + that.$store.state.UserName + '』向您发起邀约。' + '[ MyUrlHere ]MyUrlHereMyUrl=' + MyUrlPara + 'MyUrl=' + MyUrlShow;
                            let TemplateObj = {
                                TemplateIndex: 0,//订单已开始通知
                                Data: {
                                    character_string6: {value: that.CurrYYDoc.ItemCode + '>' + that.CurrYYDoc.MeetTimeStr},//订单号
                                    thing12: {value: that.CurrYYDoc.by[i].AppearanceFee > 0 ? '带币邀约(' + that.CurrYYDoc.by[i].AppearanceFee + ')' : '无币邀约'},//订单名称
                                    thing10: {value: GetItemStr3(that.CurrYYDoc.ItemStr)},//服务项目
                                    thing20: {value: '主约=' + that.$store.state.UserName},//下单人员
                                    thing18: {value: that.CurrYYDoc.MeetAddrStr.substr(2, 19)},//预约地址
                                }
                            }
                            SendWXMsg(that.CurrYYDoc.by[i]._id, Msg, TemplateObj);
                        })(i)  //  循环内有回调 : )(i)

                    for (i = 0, len = that.CurrYYDoc.by.length; i < len; i++)
                        (function (i) {
                            let Obj1 = {
                                UPTJ: {UserName: that.$store.state.UserName, RType: '我约过的'},
                                UPObj: {$addToSet: {RUsers: that.CurrYYDoc.by[i]._id}}
                            }, Obj2 = {
                                UPTJ: {UserName: that.CurrYYDoc.by[i]._id, RType: '约过我的'},
                                UPObj: {$addToSet: {RUsers: that.$store.state.UserName}}
                            };
                            myMongoDBPost("UpdateArrayAttr/RUsers", Obj1, function () {
                            });//追加一个关联用户
                            myMongoDBPost("UpdateArrayAttr/RUsers", Obj2, function () {
                            });//追加一个关联用户:往指定文档的数组属性中增加一个数组元素
                        })(i)//采用匿名函数闭包的方法解决循环内有回调的问题
                    //that.$emit("eventFromXMYY", false);
                });
            },//取消
            ResetAddr(aObj) {
                this.PCDC = {P: aObj.P, C: aObj.C, DC: aObj.DC};
                this.AddrDA = aObj.DA;
                this.SiteName = aObj.SiteName;
                this.CurrYYDoc.MeetAddrStr = aObj.P + aObj.C + aObj.DC + aObj.DA + aObj.SiteName;
                this.CurrYYDoc.Longi = aObj.Longi;
                this.CurrYYDoc.Lati = aObj.Lati;
                this.Show4 = false;
                this.AddrChanged();
            }
        }
    }
</script>

<style scoped>

</style>
